import React from 'react'
import dynamic from 'next/dynamic'
const MarkdownText = dynamic(() => import('../MarkdownText/MarkdownText'))
import Image from '../Image/Image'
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';

function RowLayoutCard(props) {
    const rowLayoutData = props.document.fields
    const desktopBannerImage = rowLayoutData?.desktopImage
    const mobileBannerImage = rowLayoutData?.mobileImage
    const isSmall = props?.isSmall
    return (
        <div className='rowlayout'>
            <div className='rowlayout-img-rap'>
                <Image
                    className={isSmall ? 'multitextImage-mobile' : 'multiTextImage-desktop'}
                    image={isSmall ? mobileBannerImage : desktopBannerImage}
                    altText={isSmall ? mobileBannerImage?.fields?.alternateText : desktopBannerImage?.fields?.alternateText}
                />
            </div>
            <div className='rowlayoutCont-wrap'>
                {rowLayoutData?.title && <MarkdownText className={'rowLayout-main-title'} value={rowLayoutData.title} />}
                <div className='textLayout'>
                    <div className='leftLayoutText'>
                        {rowLayoutData?.subTitle1 && <MarkdownText className={'ob-accordion-topic-desc'} value={rowLayoutData.subTitle1} />}
                        {rowLayoutData?.subDescription1 && <MarkdownText className={'ob-accordion-topic-desc'} value={rowLayoutData.subDescription1} />}
                    </div>
                    <div className='rightLayoutText'>
                        {rowLayoutData?.subTitle2 && <MarkdownText className={'ob-accordion-topic-desc'} value={rowLayoutData.subTitle2} />}
                        {rowLayoutData?.subDescription2 && <MarkdownText className={'ob-accordion-topic-desc'} value={rowLayoutData.subDescription2} />}
                    </div>
                </div>
                {rowLayoutData?.linkButton?.fields?.url && (
                    <div className='layoutcard-anchor-btn'>
                        <a
                            target={rowLayoutData.linkButton.fields.url.includes('http') ? '_blank' : ''}
                            rel='noreferrer'
                            href={rowLayoutData.linkButton.fields.url}
                            className={`rowlayout-link ${rowLayoutData.linkButton.fields.url.includes('http') ? 'event_outbound_link' : 'event_internal_link'} `}
                            data-action-detail={rowLayoutData.linkButton.fields.url}
                        >
                            {rowLayoutData.linkButton.fields.title}
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}

export default mediaQueryHOC(RowLayoutCard)
